.like-anchor {
    align-items: normal;
    background-color: rgba(0,0,0,0);
    border-color: rgb(0, 0, 238);
    border-style: none;
    box-sizing: content-box;
    color: white;
    cursor: pointer;
    display: inline;
    font: inherit;
    height: auto;
    padding: 0;
    perspective-origin: 0 0;
    text-align: start;
    transform-origin: 0 0;
    width: auto;
    -moz-appearance: none;
}

@supports (-moz-appearance:none) {
    .like-anchor::-moz-focus-inner {
        border: none;
        padding: 0;
    }

    .like-anchor:focus {
        outline-style: dotted;
        outline-width: 1px;
    }
}

.footer {
    font-size:12px;
    margin-bottom:10px
}

.underline {
    text-decoration: underline;
}