header {
    position: relative;
    background-color: #243d5e;
    height: 100vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
}

header video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

header .container {
    position: relative;
    z-index: 2;
}

header .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.4;
    z-index: 1;
}

.customFileUpload {
    padding: .375rem 0rem;
    width: 100%;
}

.customFileUpload.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    border: 1px solid red;
    border-radius: .25rem;
    padding-left: 5px
}

// Toast customization
.react-toast-notifications__container {
    overflow-y: hidden !important;
    z-index:999999 !important;
}

// Bootstrap 4 theme customization
.btn-my-primary {
    background-color: #223b5b !important;
}

.btn-my-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(53,90,139,.2) !important;
}

.custom-control-input:checked ~ .custom-control-label:before {
    background-color: #223b5b !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(53,90,139,.2) !important;
}

.form-control:focus {
    box-shadow: 0 0 0 0.2rem rgba(53,90,139,.2) !important;
}

.btn-light:focus {
    box-shadow: none !important;
    color: black !important;
    background-color: white !important;
}

.btn-light {
    background-color:white !important;
}

.btn-light:hover {
    color:white !important;
    background-color: #284365 !important;
}



.align-items-right {
    align-items: end !important;
}

.justify-content-right {
    justify-content: right !important;
}


.fixed-right-bottom {
    position: fixed;
    right: 10px;
    bottom: 0;
    z-index: 1030;
}

#logo {
    max-width: 600px;
}

@media screen and (max-height: 400px) {
    #logo {
        width: 250px;
        margin-bottom: 140px;
    }

    body{
        overflow:hidden
    }
}

@media screen and (min-height: 400px) and (max-height: 500px) {
    #logo {
        width: 350px;
        margin-bottom: 50px;
    }

    body {
        overflow: hidden
    }
}

@media screen and (min-height: 375px) and (max-height: 400px)  {
    #logo {
        width: 300px;
        margin-bottom: 100px;
    }

    body {
        overflow: hidden
    }
}

@media screen and (min-height: 500px) and (max-height: 900px) and (max-width: 300px) {
    #logo {
        width: 600px;
        margin-bottom: 100px;
    }

    body {
        overflow: hidden
    }
}